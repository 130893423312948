<template>
	<div>
		<bee-delete-dialog
			v-model="deleteDialog"
			moduleType="products"
			:ids="deleteItemId"
			@on-delete="fetchProducts"
		>
			{{$t('are-you-sure-you-want-to')}} 
			<span class="text-uppercase error--text">{{$t('delete')}}</span> 
			{{$t('Products.the-product')}}
		</bee-delete-dialog>

		<!-- confirm dialog -->
		<v-dialog
			v-model="confirmDialog"
			width="400"
			:persistent="changeStateLoading"
		>
			<v-card class="rounded-medium">
				<v-card-title class="justify-center text-uppercase text-h6">
					{{ $t('Products.confirm') + ' ' + titleState }}
				</v-card-title>
				<v-card-text class="text-body-1 pb-2">
					{{$t('Products.are-you-sure-you-want-to-$-this-user', { state: titleState })}}
				</v-card-text>
				<v-card-actions>
					<v-spacer/>
					<v-btn
						color="primary"
						class="rounded-small"
						:loading="changeStateLoading"
						:disabled="changeStateLoading"
						@click="changeUserState()"
					>
						{{titleState}}
					</v-btn>
					<v-btn
						class="rounded-small"
						:disabled="changeStateLoading"
						@click="confirmDialog = false; confirmId = null"
					>
						{{$t('cancel')}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- filters -->
		<v-card class="rounded-medium mb-3">
			<v-card-text class="pa-3">
				<v-row class="align-center">
					<!-- add -->
					<v-col md="8" cols="12" class="text-sm-start text-center">
						<v-btn
							dark
							color="darkGreen"
							class="rounded-small"
							:to="{ name: 'product' }"
						>
							<v-icon>mdi-plus</v-icon>
							{{$t('Products.add-new-product')}}
						</v-btn>
					</v-col>
					<!-- search -->
					<v-col md="4" cols="12" class="d-flex flex-sm-row flex-column align-center text-md-start text-center">
						<v-text-field
							v-model="search"
							:label="$t('search')"
							hide-details
							outlined
							dense
							prepend-inner-icon="mdi-magnify"
							class="rounded-medium align-self-stretch me-sm-6 mb-sm-0 mb-5"
						></v-text-field>
						<v-btn
							:color="isFiltered ? null : 'primary'"
							class="rounded-small"
							@click="options.page = 1; fetchProducts();"
						>
							{{isFiltered ? $t('cancel') : $t('search')}}
						</v-btn>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<!-- table -->
		<v-data-table
			:headers="headers"
			:items="loading ? [] : products"
			:items-per-page="15"
			:footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
			:options.sync="options"
			:server-items-length="serverItemsLength"
			:loading="loading"
			class="elevation-3 rounded-medium"
		>
			<!-- image -->
			<template v-slot:item.image="{ item }">
				<v-img
					:src="item.primary_image.url"
					:height="45"
					:width="45"
					lazy-src="../../assets/placeholder-image.png"
					transition="scale-transition"
					class="rounded-medium mx-auto"
				/>
			</template>

			<!-- categories -->
			<template v-slot:item.categories="{ item }">
				{{item.categories_ids.map(id => getCategoryById(id).name).join(', ') | isAvailable | ellipsis}}
			</template>

			<!-- date -->
			<template v-slot:item.DateOfPublication="{ item }">
				{{item.DateOfPublication ? moment(item.DateOfPublication).format('DD-MM-YYYY') : '-' }}
			</template>

			<!-- summary -->
			<template v-slot:item.summary="{ item }">
				{{item.summary | isAvailable | ellipsis}}
			</template>
			
			<!-- price -->
			<template v-slot:item.price="{ item }">
				{{item.price | isAvailable}}
			</template>

			<!-- featured -->
			<template v-slot:item.featured="{ item }">
				<v-icon v-if="item.is_featured">mdi-check-circle-outline</v-icon>
				<span v-else>-</span>
			</template>

			<!-- acitons -->
			<template v-slot:item.actions="{ item }">
				<div class="d-flex justify-center align-center">
					<v-btn
						color="deep-purple darken-2 white--text"
						small
						class="me-1 px-1 flex-shrink-1"
						min-width="20px"
						:to="{ name: 'preview', query: { url: `${previewUrl}/${item.slug}` } }"
					><v-icon>mdi-eye</v-icon></v-btn>
					<v-btn
						color="blue darken-4"
						dark
						small
						class="me-1 px-1 flex-shrink-1"
						min-width="20px"
						:to="{ name: 'product', params: { slug: item.slug, id: item.id } }"
					><v-icon>mdi-pencil</v-icon></v-btn>
					<v-btn
						color="red darken-1"
						dark
						small
						class="px-1"
						min-width="20px"
						@click="deleteItemId = item.id; deleteDialog = true"
					>
						<v-icon>mdi-delete</v-icon>
					</v-btn>
					<v-switch
						v-model="item.is_active"
						:ripple="false"
						color="darkGreen"
						hide-details
						class="ms-2 mt-0 pt-0"
						readonly
						@click="activeConfirmDialog(item)"
					></v-switch>
				</div>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import moment from 'moment'
import { products } from '@/configs/translates.config'
import { product as productPreview } from '@/configs/preview.config'
export default {
	name: 'Products',

	data: () => ({
		deleteDialog: false,
		deleteItemId: null,

		confirmDialog: false,
		changeStateLoading: false,
		confirmId: null,

		serverItemsLength: 0,
		options: {},

		// filter
		search: null,
		isFiltered: false,

		// helpers
		titleState: null,
		loading: false,
		previewUrl: productPreview
	}),

	watch: {
		options() {
			this.fetchProducts();
		}
	},

	computed: {
		...mapState({
			products: state => state.products.products,
			categories: state => state.categories.categories,
		}),

		...mapGetters({
			getCategoryById: 'categories/getCategoryById'
		}),

		headers() {
			return [
				{ text: this.$t('headers.image'), value: 'image', align: 'center', sortable: false },
				{ text: this.$t('headers.name'), value: 'name', align: 'center', class: 'primary--text', cellClass: 'primary--text' },
				{ text: this.$t('headers.category'), value: 'categories', align: 'center', sortable: false },
				{ text: this.$t('headers.date'), value: 'DateOfPublication', align: 'center', width: 110, sortable: false },
				{ text: this.$t('headers.summary'), value: 'summary', align: 'center', width: 200 },
				{ text: this.$t('headers.price'), value: 'price', align: 'center' },
				{ text: this.$t('headers.featured'), value: 'featured', align: 'center' },
				{ text: this.$t('headers.actions'), value: 'actions', align: 'center', sortable: false },
			]
		},

		filteredProducts() {
			return this.filterByName(this.isFiltered, this.products, this.search)
		},
	},

	methods: {
		fetchProducts() {
			this.loading = true;
			Promise.all([
				this.categories.length === 0 ? this.$store.dispatch('categories/fetchAll') : null,
				this.$store.dispatch('products/fetchAll', {
					name: this.search,
					page: this.options.page - 1,
					perPage: this.options.itemsPerPage
				}).then(({ total }) => {
					this.serverItemsLength = total;
				})
			]).finally(() => {
				this.loading = false;
			})
		},

		activeConfirmDialog(item) {
			this.confirmId = item.id;
			this.titleState = item.is_active ? this.$t('Products.deactivate') : this.$t('Products.activate');
			this.confirmDialog = true;
		},

		changeUserState() {
			this.changeStateLoading = true;
			this.$store.dispatch('products/changeState', {
				id: this.confirmId
			}).then(() => {
				this.confirmDialog = false
				this.$eventBus.$emit('show-snackbar', false, this.$t('$-is-updated-successfully', { name: products.en.singular }));
				this.fetchProducts()
			}).finally(() => {
				this.changeStateLoading = false;
			})
		},

		// helpers
		moment
	},
};
</script>

<style>
</style>
